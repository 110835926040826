.backdrop {
    background-color: rgb(32, 174, 240);
    height          : 100%;
    width           : 99%;
    overflow        : hidden;
    position        : absolute;
    top             : 0;
    left            : 0;
}

.cloud1 {
    position        : absolute;
    width           : 10%;
    height          : 7%;
    background-color: #fff;
    top             : 10%;
    left            : 0%;
}

@keyframes cloudAnimation1 {
    0% {
        margin-left: -10%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud1 {
    -webkit-animation: cloudAnimation1 20s linear infinite;
    -moz-animation   : cloudAnimation1 20s linear infinite;
    animation        : cloudAnimation1 20s linear infinite;
    transform        : translateY(-10%);
}

.cloud2 {
    position        : absolute;
    width           : 9%;
    height          : 7%;
    background-color: #fff;
    top             : 70%;
    left            : 0%;
}

@keyframes cloudAnimation2 {
    0% {
        margin-left: -9%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud2 {
    -webkit-animation: cloudAnimation2 20s linear infinite;
    -moz-animation   : cloudAnimation2 20s linear infinite;
    animation        : cloudAnimation2 20s linear infinite;

    transform: translateY(-70%);
}

.cloud3 {
    position        : absolute;
    width           : 8%;
    height          : 7%;
    background-color: #fff;
    top             : 100%;
    left            : 0%;
}

@keyframes cloudAnimation3 {
    0% {
        margin-left: -18%;
    }

    100% {
        margin-left: 110%;
    }
}

.cloud3 {
    -webkit-animation: cloudAnimation3 18s linear infinite;
    -moz-animation   : cloudAnimation3 18s linear infinite;
    animation        : cloudAnimation3 18s linear infinite;

    transform: translateY(-100%);
}

.cloud4 {
    position        : absolute;
    width           : 20%;
    height          : 7%;
    background-color: #fff;
    top             : 30%;
    left            : 0%;

    &::before {
        content         : '';
        position        : absolute;
        top             : -90%;
        left            : 45%;
        transform       : translateX(-45%);
        height          : 100%;
        width           : 30%;
        background-color: #fff;
    }

    &::after {
        content         : '';
        position        : absolute;
        top             : 90%;
        left            : 70%;
        transform       : translateX(-70%);
        height          : 100%;
        width           : 30%;
        background-color: #fff;
    }
}

@keyframes cloudAnimation4 {
    0% {
        margin-left: -40%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud4 {
    -webkit-animation: cloudAnimation4 25s linear infinite;
    -moz-animation   : cloudAnimation4 25s linear infinite;
    animation        : cloudAnimation4 25s linear infinite;

    transform: translateY(-30%);
}

.cloud5 {
    position        : absolute;
    width           : 15%;
    height          : 7%;
    background-color: #fff;
    top             : 60%;
    left            : 0%;

    &::before {
        content         : '';
        position        : absolute;
        top             : -90%;
        left            : 50%;
        transform       : translateX(-50%);
        height          : 100%;
        width           : 50%;
        background-color: #fff;
    }

}

@keyframes cloudAnimation5 {
    0% {
        margin-left: -45%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud5 {
    -webkit-animation: cloudAnimation5 28s linear infinite;
    -moz-animation   : cloudAnimation5 28s linear infinite;
    animation        : cloudAnimation5 28s linear infinite;

    transform: translateY(-60%);
}

.cloud6 {
    position        : absolute;
    width           : 18%;
    height          : 7%;
    background-color: #fff;
    top             : 40%;
    left            : 0%;

    &::after {
        content         : '';
        position        : absolute;
        top             : 90%;
        left            : 50%;
        transform       : translateX(-50%);
        height          : 100%;
        width           : 50%;
        background-color: #fff;
    }
}

@keyframes cloudAnimation6 {
    0% {
        margin-left: -58%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud6 {
    -webkit-animation: cloudAnimation6 32s linear infinite;
    -moz-animation   : cloudAnimation6 32s linear infinite;
    animation        : cloudAnimation6 32s linear infinite;

    transform: translateY(-40%);
}

.cloud7 {
    position        : absolute;
    width           : 25%;
    height          : 7%;
    background-color: #fff;
    top             : 85%;
    left            : 0%;

    &::after {
        content         : '';
        position        : absolute;
        top             : 90%;
        left            : 35%;
        transform       : translateX(-35%);
        height          : 100%;
        width           : 35%;
        background-color: #fff;
    }

    &::before {
        content         : '';
        position        : absolute;
        top             : -90%;
        left            : 65%;
        transform       : translateX(-65%);
        height          : 100%;
        width           : 40%;
        background-color: #fff;
    }
}

@keyframes cloudAnimation7 {
    0% {
        margin-left: -75%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud7 {
    -webkit-animation: cloudAnimation7 33s linear infinite;
    -moz-animation   : cloudAnimation7 33s linear infinite;
    animation        : cloudAnimation7 33s linear infinite;

    transform: translateY(-85%);
}

.cloud8 {
    position        : absolute;
    width           : 12%;
    height          : 7%;
    background-color: #fff;
    top             : 62%;
    left            : 0%;
}

@keyframes cloudAnimation8 {
    0% {
        margin-left: -80%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud8 {
    -webkit-animation: cloudAnimation8 33s linear infinite;
    -moz-animation   : cloudAnimation8 33s linear infinite;
    animation        : cloudAnimation8 33s linear infinite;

    transform: translateY(-62%);
}

.cloud9 {
    position        : absolute;
    width           : 20%;
    height          : 7%;
    background-color: #fff;
    top             : 78%;
    left            : 0%;


    &::after {
        content         : '';
        position        : absolute;
        top             : 90%;
        left            : 35%;
        transform       : translateX(-35%);
        height          : 100%;
        width           : 35%;
        background-color: #fff;
    }
}

@keyframes cloudAnimation9 {
    0% {
        margin-left: -90%;
    }

    100% {
        margin-left: 100%;
    }
}

.cloud9 {
    -webkit-animation: cloudAnimation9 35s linear infinite;
    -moz-animation   : cloudAnimation9 35s linear infinite;
    animation        : cloudAnimation9 35s linear infinite;

    transform: translateY(-78%);
}